import apiService from './ApiService';

export default {
    load: (id) => {
        return apiService.get(`/api/section/${id}/form`);
    },
    save: (id, def) => {
        if (def.definition.id) {
            return apiService.put(`/api/section/${id}/form`, { data: def });
        } else {
            return apiService.post(`/api/section/${id}/form`, { data: def });
        }
    },
    storeData: (stoneId, sectionId, data) => {
        if (data instanceof FormData) {
            return apiService.post(`/api/analysis/stone/${stoneId}/section/${sectionId}`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
        } else {
            return apiService.post(`/api/analysis/stone/${stoneId}/section/${sectionId}`, { 'data': data });
        }
    },
    readData: (stoneId, sectionId) => {
        return apiService.get(`/api/analysis/stone/${stoneId}/section/${sectionId}`);
    }

};
