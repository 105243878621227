import apiService from './../resources/js/services/ApiService';

export default {
    create: (model) => {
        return apiService.post(`/api/create-template`, {
            data: {
                template: model
            }
        })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log("TEMPLATE CREATE ERROR: ", error);
                return false;
            })
    },
    updateLayout: (template_id, layout) => {
        return apiService.put(`/api/templates/${template_id}/layout`, {
            data: {
                'layout': layout
            }
        })
            .then((response) => {
                return response;
            })
            .catch((error) => {
                console.log("TEMPLATE CREATE ERROR: ", error);
                return false;
            })
    },
    updateBackgroundImage: (template_id, backgroundImage) => {
        const formData = new FormData();
        formData.set("backgroundimage", backgroundImage);

        return apiService.post(`/api/templates/${template_id}/background`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
            .then((response) => {
                return response.data.template;
            })
            .catch((error) => {
                console.log("TEMPLATE CREATE ERROR: ", error);
                return false;
            })
    },

    search: (filters, sort, pagination, format) => {
        const payload = {
            "filter": filters,
            "sort": sort,
            "pagination": pagination,
            "format": format
        };
        Object.keys(payload).map(k => !payload[k] ? delete payload[k] : false);
        return apiService.post(`/api/templates/search`, {
            data: payload
        }).then((response) => {
            console.log("key", response.data.key);
            return apiService.get('/api/templates/search-results/' + response.data.key)
                .then((response) => {
                    console.log("templateService.search: ", response.data.pagination);
                    return {
                        "templates": response.data.templates,
                        "paginator": response.data.pagination
                    }
                });
        }).catch((error) => {
            console.log("GET TEMPLATES ERROR: ", error);
            return false;
        });
    },

    delete(id) {
        return apiService.delete(`/api/template/${id}`);
    },

    load: (id) => {
        return apiService.get(`/api/templates/${id}`);
    },
}
