// Meta classification of widgets, partition the next array in different types
const _reportWidgetsMetaClasses = ['report-widget'];

const _commonInstanceProperties = {
    id: null,
    selected: false,
}
const _dataProperty = {
    data: null,
}
const _reportWidgetsClasses = [
    {
        component: "WidgetReportText",
        propertiesEditor: "PropertiesEditorText",
        classProperties: {
            type: 'report-widget',
            name: 'richtext',
            code: 'rt',
        },
        instanceProperties: {
            ..._commonInstanceProperties,
            // name: 'text',
            // code: 'text',
            x: 0,
            y: 0,
            w: 256,
            h: 128,
        },
        runtimeProperties: {
            ..._dataProperty,
        },

    },
    {
        component: "WidgetReportSN",
        propertiesEditor: "PropertiesEditorSN",
        classProperties: {
            type: 'report-widget',
            name: 'serial',
            code: 'serial',
        },
        instanceProperties: {
            ..._commonInstanceProperties,
            // name: 'text',
            // code: 'text',
            x: 0,
            y: 0,
            w: undefined,
            h: undefined,
        },
        runtimeProperties: {
            ..._dataProperty,
        },

    },
    {
        component: "WidgetReportQrCode",
        propertiesEditor: "PropertiesEditorQrCode",
        classProperties: {
            type: 'report-widget',
            name: 'qrcode',
            code: 'qrcode',
        },
        instanceProperties: {
            ..._commonInstanceProperties,
            // name: 'text',
            // code: 'text',
            x: 0,
            y: 0,
            w: 116,
            h: 116,
        },
        runtimeProperties: {
            ..._dataProperty,
        },

    },
    {
        component: "WidgetReportData",
        propertiesEditor: "PropertiesEditorData",
        classProperties: {
            type: 'report-widget',
            name: 'data',
            code: 'data',
            label: '',
        },
        instanceProperties: {
            ..._commonInstanceProperties,
            field: null,
            x: 0,
            y: 0,
            w: undefined,
            h: undefined,
            font: {
                family: 'Urbanist',
                weight: 400,
                size: 16,
            },
            alignment: 'left',
            color: "rgb(0, 0, 0)",
            label: '',
            labelPosition: 'before'
        },
        runtimeProperties: {
            ..._dataProperty,
            reference: null
        },
    },
];

export const reportWidgetsClasses = () => {
    return _reportWidgetsClasses;
};
export const reportWidgetsMetaClasses = () => {
    return _reportWidgetsMetaClasses;
};
