// Meta classification of widgets, partition the next array in different types
const _formWidgetsMetaClasses = ['form-layout', 'form-widget'];

export const formWidgetsValueTypes = ['one', 'multi', 'bool', 'data'];

const _commonInstanceProperties = {
    id: null,
    name: '',
    pathname: [],
    rules: []
}
const _statusProperties = {
    disabled: false,
    readonly: false
};
const _commonRuntimeProperties = {
    selected: false,
};
const _dataProperty = {
    data: null,
}
const _commonValidationProperties = {
    required: true
}
const _formCoreWidgetsClasses = [
    {
        component: "WidgetContainer",
        classProperties: {
            type: 'form-layout',
            valueType: 'none',
            name: 'container',
            code: 'container',
            label: 'container',
            icon: '/assets/icons/container.svg',
        },
        instanceProperties: {
            ..._commonInstanceProperties,
            label: "",
            blocks: []
        },
        runtimeProperties: {
            ..._commonRuntimeProperties,
        },
    },
];
const _formWidgetsClasses = [
    {
        component: "WidgetInputText",
        propertiesEditor: "PropertiesEditorInputText",
        classProperties: {
            type: 'form-widget',
            valueType: 'one',
            name: 'input-text',
            code: 'inpt',
            label: 'input text',
            icon: '/assets/icons/input-text.svg',
        },
        instanceProperties: {
            ..._commonInstanceProperties,
            ..._statusProperties,
            label: 'label',
            placeholder: ''
        },
        runtimeProperties: {
            ..._commonRuntimeProperties,
            ..._dataProperty,
        },
        validationProperties: {
            ..._commonValidationProperties
        }

    },
    {
        component: "WidgetInputNumber",
        propertiesEditor: "PropertiesEditorInputNumber",
        classProperties: {
            type: 'form-widget',
            valueType: 'one',
            name: 'input-number',
            code: 'inpn',
            label: 'input number',
            icon: '/assets/icons/input-number.svg',
        },
        instanceProperties: {
            ..._commonInstanceProperties,
            ..._statusProperties,
            label: 'label',
            placeholder: ''
        },
        runtimeProperties: {
            ..._commonRuntimeProperties,
            ..._dataProperty,
        },
        validationProperties: {
            ..._commonValidationProperties
        },
    },
    {
        component: "WidgetInputDate",
        propertiesEditor: "PropertiesEditorInputDate",
        classProperties: {
            type: 'form-widget',
            valueType: 'one',
            name: 'input-date',
            code: 'inpd',
            label: 'input date',
            icon: '/assets/icons/input-date.svg',
        },
        instanceProperties: {
            ..._commonInstanceProperties,
            ..._statusProperties,
            label: 'label',
            placeholder: '',
        },
        runtimeProperties: {
            ..._commonRuntimeProperties,
            ..._dataProperty,
        },
        validationProperties: {
            ..._commonValidationProperties
        }
    },
    {
        component: "WidgetCheckboxGroup",
        propertiesEditor: "PropertiesEditorCheckboxGroup",
        classProperties: {
            type: 'form-widget',
            name: 'checkbox-group',
            valueType: 'multi',
            code: 'cbg',
            label: 'checkbox group',
            icon: '/assets/icons/checkbox-group.svg',
        },
        instanceProperties: {
            ..._commonInstanceProperties,
            ..._statusProperties,
            label: 'label',
            options: [{
                label: 'option-1',
            }, {
                label: 'option-2',
            }, {
                label: 'option-3',
            }] // { value, label}
        },
        runtimeProperties: {
            ..._commonRuntimeProperties,
            ..._dataProperty,
        },
        validationProperties: {
            ..._commonValidationProperties
        }
    },
    {
        component: "WidgetCheckbox",
        propertiesEditor: "PropertiesEditorCheckbox",
        classProperties: {
            type: 'form-widget',
            name: 'checkbox',
            valueType: 'bool',
            code: 'cb',
            label: 'checkbox',
            icon: '/assets/icons/checkbox.svg',
        },
        instanceProperties: {
            ..._commonInstanceProperties,
            ..._statusProperties,
            label: 'label',
            option: {
                label: 'option-zed',
            }
        },
        runtimeProperties: {
            ..._commonRuntimeProperties,
            ..._dataProperty,
        },
        validationProperties: {
            ..._commonValidationProperties
        }
    },
    {
        component: "WidgetRadioGroup",
        propertiesEditor: "PropertiesEditorRadioGroup",
        classProperties: {
            type: 'form-widget',
            name: 'radio-group',
            code: 'rbg',
            label: 'radio group',
            icon: '/assets/icons/radio-group.svg',
        },
        instanceProperties: {
            ..._commonInstanceProperties,
            ..._statusProperties,
            label: 'label',
            options: [{
                label: 'option-alfa',
            }, {
                label: 'option-beta',
            }]
        },
        runtimeProperties: {
            ..._commonRuntimeProperties,
            ..._dataProperty,
        },
        validationProperties: {
            ..._commonValidationProperties
        }
    },
    {
        component: "WidgetButtonGroup",
        propertiesEditor: "PropertiesEditorButtonGroup",
        classProperties: {
            type: 'form-widget',
            valueType: 'one',
            name: 'button-group',
            code: 'bg',
            label: 'button group',
            icon: '/assets/icons/button-group.svg',
        },
        instanceProperties: {
            ..._commonInstanceProperties,
            ..._statusProperties,
            label: 'label',
            options: [{
                label: 'alfa',
            }, {
                label: 'beta',
            }]
        },
        runtimeProperties: {
            ..._commonRuntimeProperties,
            ..._dataProperty,
        },
        validationProperties: {
            ..._commonValidationProperties
        }
    },
    {
        component: "WidgetRange",
        propertiesEditor: "PropertiesEditorRange",
        classProperties: {
            type: 'form-widget',
            valueType: 'one',
            name: 'range',
            code: 'rng',
            label: 'range',
            icon: '/assets/icons/slider.svg',
        },
        instanceProperties: {
            ..._commonInstanceProperties,
            ..._statusProperties,
            label: 'label',
            min: 0,
            max: 100,
            step: 1
        },
        runtimeProperties: {
            ..._commonRuntimeProperties,
            ..._dataProperty,
        },
        validationProperties: {
            ..._commonValidationProperties
        }
    },
    // {
    //     component: "WidgetSelect",
    //     propertiesEditor: "PropertiesEditorSelect",
    //     classProperties: {
    //         type: 'form-widget',
    //         name: 'select',
    //         code: 'sel',
    //         label: 'select',
    //         icon: '/assets/icons/select.svg',
    //     },
    //     instanceProperties: {
    //         ..._commonInstanceProperties,
    //         ..._statusProperties,
    //         label: 'label',
    //         placeholder: 'placeholder',
    //         options: [{
    //             label: 'option-alfa',
    //         }, {
    //             label: 'option-beta',
    //         }],
    //         multiple: false
    //     },
    //     runtimeProperties: {
    //         ..._commonRuntimeProperties,
    //         ..._dataProperty,
    //     },
    //     validationProperties: {
    //         ..._commonValidationProperties
    //     }
    // },
    {
        component: "WidgetCombobox",
        propertiesEditor: "PropertiesEditorCombobox",
        classProperties: {
            type: 'form-widget',
            valueType: 'multi',
            name: 'selectbox',
            code: 'slct',
            label: 'selectbox',
            icon: '/assets/icons/combobox.svg',
        },
        instanceProperties: {
            ..._commonInstanceProperties,
            ..._statusProperties,
            label: 'label',
            placeholder: 'placeholder',
            options: [{
                label: 'option-alfa',
            }, {
                label: 'option-beta',
            }],
            multiple: false
        },
        runtimeProperties: {
            ..._commonRuntimeProperties,
            ..._dataProperty,
        },
        validationProperties: {
            ..._commonValidationProperties
        }
    },
    {
        component: "WidgetImage",
        propertiesEditor: "PropertiesEditorImage",
        classProperties: {
            type: 'form-widget',
            name: 'image',
            valueType: 'data',
            code: 'img',
            label: 'image',
            icon: '/assets/icons/image.svg',
        },
        instanceProperties: {
            ..._commonInstanceProperties,
            ..._statusProperties,
            label: 'label',
            w: 256,
            h: 256
        },
        runtimeProperties: {
            ..._commonRuntimeProperties,
            ..._dataProperty,
        },
        validationProperties: {
            ..._commonValidationProperties
        }
    },
    {
        component: "WidgetFile",
        propertiesEditor: "PropertiesEditorFile",
        classProperties: {
            type: 'form-widget',
            valueType: 'data',
            name: 'file',
            code: 'img',
            label: 'file',
            icon: '/assets/icons/file.svg',
        },
        instanceProperties: {
            ..._commonInstanceProperties,
            ..._statusProperties,
            label: 'label',
        },
        runtimeProperties: {
            ..._commonRuntimeProperties,
            ..._dataProperty,
        },
        validationProperties: {
            ..._commonValidationProperties
        }
    },
];

export const formWidgetsClasses = () => {
    return _formWidgetsClasses;
};
export const allWidgetsClasses = () => {
    return [..._formCoreWidgetsClasses, ..._formWidgetsClasses];
};
export const formWidgetsMetaClasses = () => {
    return _formWidgetsMetaClasses;
};
