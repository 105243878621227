import { defineStore } from 'pinia';
import { useRouter } from 'vue-router';
import { reactive, computed } from 'vue';
import ApiService from '../services/ApiService';

export const useUserInfoStore = defineStore('userInfoStore', () => {
    const router = useRouter();

    const userInfo = reactive({
        user: {},
        isAuthenticated: false,
        token: '',
        errors: {}
    });

    // Getters
    const currentUser = computed(() => userInfo.user);
    const isUserAuthenticated = computed(() => userInfo.isAuthenticated);
    const getRole = computed(() => userInfo.user.role);
    const getErrors = computed(() => userInfo.errors);

    // Actions
    const setError = (error) => {
        userInfo.errors = { ...error };
    };

    const setAuth = (user, token) => {
        userInfo.errors = {};
        userInfo.user = user;
        userInfo.token = token;
        userInfo.isAuthenticated = true;

        localStorage.setItem('token', token);
        localStorage.setItem('user', JSON.stringify(user));
    };

    const purgeAuth = () => {
        userInfo.errors = null;
        userInfo.user = {};
        userInfo.isAuthenticated = false;
        userInfo.token = '';

        localStorage.removeItem('token');
        localStorage.removeItem('user');

        router.push('/login');
    };

    const login = async (account, credentials) => {
        try {
            purgeAuth();

            const data = await ApiService.post('/api/login', {
                email: account,
                password: credentials,
            });
            console.log('dfata', data);

            if (!data.user === 'fail') {
                setError(data.errors);
                return null;
            } else {
                setAuth(data.user, data.access_token);
                return data;
            }
        } catch (error) {
            console.log('error:', error);
            if (error.response) {
                setError(error.response.data.errors);
            }
            return null;
        }
    };

    const logout = async () => {
        purgeAuth();
        await ApiService.post('/api/logout', {});
    };

    const verifyAuth = async () => {
        try {
            const { data } = await ApiService.get('/api/profile');
            if (data.status === 'success') {
                const profile = data.data.profile;
                const token = localStorage.getItem('token') ?? '';
                setAuth(profile, token);
            } else {
                purgeAuth();
            }
        } catch {
            purgeAuth();
        }
    };

    const initialize = () => {
        const token = localStorage.getItem("token");
        const user = localStorage.getItem("user");

        if (token && user) {
            userInfo.token = token;
            userInfo.user = JSON.parse(user);
            userInfo.isAuthenticated = true;
        } else {
            purgeAuth();
        }
    };

    const profileAdmin = async (userID) => {
        try {
            const { data } = await ApiService.get(`/api/user/${userID}`);
            setAuth(data.user, localStorage.token);
            console.log('userInfo.user', userInfo.user);
            return data.user;
        } catch (error) {
            console.error("Failed to fetch admin data:", error);
        }
    };

    const firstLogin = async (model) => {
        try {
            const data = await ApiService.post("/api/first-login", {
                data: model
            });
            console.log('data', data)

            if (data.status === "fail") {
                setError(data.errors);
                return null;
            } else {
                profileAdmin(userInfo.user.id);

                return data;
            }
        } catch (error) {
            if (error.response) {
                setError(error.response.data.errors);
            }
            return null;
        }
    };

    const sendResetPassword = async (email) => {
        try {
            const data = await ApiService.post("/api/send-reset-password-email", {
                email
            });

            if (data.status === "fail") {
                return data;
            } else {
                return data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
            return null;
        }
    };

    const resetPassword = async (payload) => {
        try {
            const data = await ApiService.post("/api/reset-password", {
                data: {
                    payload
                }
            });

            if (data.status === "fail") {
                return data;
            } else {
                return data;
            }
        } catch (error) {
            if (error.response) {
                return error.response.data;
            }
            return null;
        }
    }

    return {
        userInfo,
        currentUser,
        isUserAuthenticated,
        getRole,
        getErrors,
        setError,
        setAuth,
        purgeAuth,
        login,
        logout,
        verifyAuth,
        initialize,
        profileAdmin,
        firstLogin,
        sendResetPassword,
        resetPassword
    };
})


// export const useUserInfoStore = defineStore('userInfoStore', {
//     state: () => ({
//         userInfo: {
//           user: ref({}),
//           isAuthenticated: false,
//           token: '',
//           errors: {}
//         }
//     }),
//     getters: {
//         currentUser: (state) => {
//             return state.userInfo.user;
//         },

//         isUserAuthenticated: (state) => {
//             return state.userInfo.isAuthenticated;
//         },

//         getRole: (state) => {
//             return state.userInfo.user.role;
//         },

//         getErrors: (state) => {
//             return state.userInfo.errors;
//         },
//     },
//     actions: {
//         setError(error) {
//             this.userInfo.errors = { ...error };
//         },

//         setAuth(user, token) {
//             this.userInfo.errors = {};
//             this.userInfo.user = user;
//             this.userInfo.token = token;
//             this.userInfo.isAuthenticated = true;

//             localStorage.setItem('token', token);
//             localStorage.setItem('user', JSON.stringify(user));
//         },

//         purgeAuth() {
//             this.userInfo.errors = null;
//             this.userInfo.user = {};
//             this.userInfo.isAuthenticated = false;
//             this.userInfo.token = '';

//             localStorage.setItem('token', '');
//             localStorage.setItem('user', '');

//             router.push('/login');
//         },

//         async login(account, credentials) {
//             try {
//                 this.purgeAuth();

//                 const data = await ApiService.post('/api/login', {
//                     email: account,
//                     password: credentials,
//                 });
//                 console.log(data);
//                 if (data.status === 'fail') {
//                     this.setError(data.errors);
//                     return null;
//                 } else {
//                     console.log('login success', data);
//                     this.setAuth(data['user'], data['access_token']);
//                     return data;
//                 }
//             } catch (error) {
//                 if (error.response) {
//                     this.setError(error.response.data.errors);
//                 }
//                 return null; // Restituisci null o gestisci come preferisci in caso di errore
//             }
//         },

//         async logout() {
//             this.purgeAuth();
//             await ApiService.post('/api/logout', {});
//         },

//         async verifyAuth() {
//             await ApiService.get('/api/profile')
//                 .then(async (response) => {
//                     const data = response.data;
//                     if (data.status == 'success') {
//                         const profile = data.data.profile;

//                         const token = localStorage.getItem('token') ?? '';

//                         this.setAuth(profile, token);
//                     } else {
//                         this.purgeAuth();
//                     }
//                 })
//                 .catch(() => {
//                     this.purgeAuth();
//                 });
//         },

//         initialize() {
//             const token = localStorage.getItem("token");
//             const user = localStorage.getItem("user");

//             if (token && user) {
//                 this.userInfo.token = token;
//                 this.userInfo.user = JSON.parse(user);
//                 this.userInfo.isAuthenticated = true;
//             } else {
//                 this.purgeAuth();
//             }
//         },
//         async profileAdmin(userID) {

//             try {
//                 const profile = await ApiService.get(`/api/user/${userID}`);
//                 console.log("updateuser", profile.data.user);
//                 this.userInfo.user = profile.data.user;
//                 console.log('user info', this.userInfo.user);
//                 return profile.data.user;
//             } catch (error) {
//                 console.error("Failed to fetch admin data:", error);
//             }
//         },

//         async first_login(model) {
//             try {
//                 const data  = await ApiService.post("/api/first-login", {
//                     data: model
//                 });
//                 if (data.status === "fail") {
//                     this.setError(data.errors);
//                     return null;
//                 } else {
//                     console.log('change password success', data);
//                     return data;
//                 }
//             } catch (error) {
//                 if (error.response) {
//                     this.setError(error.response.data.errors);
//                 }
//                 return null;  // Restituisci null o gestisci come preferisci in caso di errore
//             }
//         },

//         ///reset-password
//         async reset_password(email) {
//             try {
//                 const { data } = await ApiService.post("/api/reset-password", {
//                     data: {
//                         email: email
//                     }
//                 });
//                 if (data.status === "fail") {
//                     return data;
//                 } else {
//                     console.log('change password success', data);
//                     return data;
//                 }
//             } catch (error) {
//                 if (error.response) {
//                     return error.response.data;
//                 }
//                 return null;
//             }
//         }
//     }
// });
