// services/apiService.js
import axios from 'axios';
import router from '../routes/routes';
import { useLoaderStore } from '../stores/loaderStore';

// Crea un'istanza Axios
const apiClient = axios.create({
    baseURL: import.meta.env.VITE_APP_URL, // Inserisci qui la tua base URL
    withCredentials: false, // Questo può essere true se si utilizzano credenziali
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
    },
    timeout: 10000, // Timeout di 10 secondi
});

let activeRequests = 0;
let loaderTimeout = null;

// Intercettore per aggiungere il token di autenticazione a ogni richiesta
apiClient.interceptors.request.use(
    (config) => {
        const loaderStore = useLoaderStore();
        activeRequests++;

        if (activeRequests === 1) {
            loaderTimeout = setTimeout(() => {
                loaderStore.loader = true;
            }, 1000);
        }

        const token = localStorage.getItem('token'); // O da dove ottieni il tuo token
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Intercettore per gestire gli errori delle risposte
apiClient.interceptors.response.use(
    (response) => {
        activeRequests--;

        if (activeRequests === 0) {
            if (loaderTimeout) {
                clearTimeout(loaderTimeout);
                loaderTimeout = null;
            }
            const loaderStore = useLoaderStore();
            loaderStore.loader = false;
        }

        return response;
    },
    (error) => {
        activeRequests--;

        if (activeRequests === 0) {
            if (loaderTimeout) {
                clearTimeout(loaderTimeout);
                loaderTimeout = null;
            }
            const loaderStore = useLoaderStore();
            loaderStore.loader = false
        }

        // Puoi gestire gli errori in base allo status della risposta
        if (error.response.status === 401) {
            // Gestisci l'errore di autenticazione (ad esempio, reindirizza alla pagina di login)
            // Rimuovi il token se esiste
            localStorage.removeItem('token');
            // Reindirizza alla pagina di login
            router.push({ name: 'login' });
        }
        return Promise.reject(error);
    }
);

export default {
    // Esempio di chiamata GET
    get(resource) {
        try {
            return apiClient.get(resource).then((response) => {
                return response.data;
            });
        } catch (exception) {
            console.log('EXCEPTION: ', exception);
            return {
                status: 'fail',
                errors: [exception],
            };
        }
    },
    // Esempio di chiamata POST
    post(resource, data, opt = null) {
        try {
            return apiClient.post(resource, data, opt).then((response) => {
                return response.data;
            });
        } catch (exception) {
            console.log('EXCEPTION: ', exception);
            return {
                status: 'fail',
                errors: [exception],
            };
        }
    },
    // Esempio di chiamata PUT
    put(resource, data, opt = null) {
        try {
            return apiClient.put(resource, data, opt).then((response) => {
                return response.data;
            });
        } catch (exception) {
            console.log('EXCEPTION: ', exception);
            return {
                status: 'fail',
                errors: [exception],
            };
        }
    },
    // Esempio di chiamata DELETE
    delete(resource) {
        try {
            return apiClient.delete(resource).then((response) => {
                return response.data;
            });
        } catch (exception) {
            console.log('EXCEPTION: ', exception);
            return {
                status: 'fail',
                errors: [exception],
            };
        }
    },
    // Esempio di chiamata PATCH
    patch(resource, data) {
        try {
            return apiClient.patch(resource, data).then((response) => {
                return response.data;
            });
        } catch (exception) {
            console.log('EXCEPTION: ', exception);
            return {
                status: 'fail',
                errors: [exception],
            };
        }
    },
};
